import "../styles/Storage.scss";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";

export function Storage() {
  return (
    <div className="storage-container">
      <ScrollAnimation>
        <div className="storage-content">
          <div className="storage-image">
            <img
              src="/assets/services/storage.jpg"
              alt="skladiste"
              className="img"
            />
          </div>

          <div className="storage-text">
            <p className="above-title">SIGURNO SKLADIŠTENJE ZA VAŠE POTREBE</p>
            <h2 className="service-title">USLUGA SKLADIŠTENJA</h2>
            <p className="description">
              Naša usluga skladištenja robe za poslovne korisnike pruža sigurno,
              organizirano i fleksibilno rješenje za pohranu vaših proizvoda,
              materijala i drugih poslovnih resursa. Bilo da se radi o paletnoj
              robi, industrijskom materijalu ili drugim vrstama tereta, naš
              skladišni prostor je idealno mjesto za sigurno i efikasno
              upravljanje vašim inventarom.
            </p>
            <ul className="storage-services">
              <li>
                <div className="inline-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="icon"
                  >
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                  </svg>
                  <p>SKLADIŠTENJE PALETNE ROBE</p>
                </div>

                <p>
                  Pružamo specijalizirani prostor za skladištenje paletirane
                  robe, uključujući teške industrijske proizvode, gotove
                  proizvode, sirovine, ambalažu i druge artikle. Naša skladišta
                  su opremljena za sigurno i učinkovito rukovanje paletama svih
                  veličina i težina.
                </p>
              </li>
              <li>
                <div className="inline-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="icon"
                  >
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                  </svg>
                  <span>SIGURNOST I ZAŠTITA</span>
                </div>
                <p>
                  Vaša roba je sigurna u našim skladištima koja su opremljena
                  sustavima za video nadzor, alarmima i drugim sigurnosnim
                  mjerama. Svi predmeti su pohranjeni u kontroliranim uvjetima,
                  čime se sprječavaju oštećenja i osigurava zaštita tijekom
                  cijelog perioda skladištenja.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
