import { HomeSection } from "../section/HomeSection";
import { QuickInfo } from "../section/QuickInfo";
import { Statistic } from "../components/Statistic/Statistic";
import { TransportService } from "../section/TransportService";
import { FurnitureService } from "../section/FurnitureService";
import { Partners } from "../section/Partners";
import { Footer } from "../layouts/Footer/Footer";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";
import { FurnitureDisassembly } from "../section/FurnitureDisassembly";
import { PalletDelivery } from "../section/PalletDelivery";
import { Storage } from "../section/Storage";

export function HomePage() {
  return (
    <>
      <div id="home"></div>
      <HomeSection />

      <ScrollAnimation>
        <QuickInfo />
      </ScrollAnimation>

      <div id="about">
        <Statistic />
      </div>

      <div id="service">
        <TransportService />
        <Storage />
        <FurnitureService />
        <FurnitureDisassembly />
        <PalletDelivery />
      </div>

      <div id="partners">
        <Partners />
      </div>

      <Footer />
    </>
  );
}
