import "../styles/FurnitureDisassembly.scss";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";

export function FurnitureDisassembly() {

  return (
    <div className="disassembly-container">
      <ScrollAnimation>
        <div className="disassembly-content">
          <div className="disassembly-image">
            <img
              src="/assets/services/utovar.jpg"
              alt="utovar"
              className="img1"
            />
            <img
              src="/assets/services/paleta.jpg"
              alt="paleta"
              className="img2"
            />
            <img
              src="/assets/services/paketi.jpg"
              alt="paket"
              className="img3"
            />
          </div>
          <div className="disassembly-text">
            <p className="above-title">SIGURNO I POUZDANO</p>
            <h2 className="service-title">
              USLUGA DEMONTAŽE I PAKIRANJA NAMJEŠTAJA
            </h2>
            <p className="description">
              Naša usluga demontaže i pakiranja namještaja pruža vam potpunu
              podršku prilikom selidbe, renoviranja ili bilo koje druge
              situacije koja zahtijeva rastavljanje i sigurno pakiranje
              namještaja. Uz profesionalan pristup i pažljivo rukovanje,
              garantiramo da vaš namještaj stigne na novu lokaciju bez oštećenja
              i u savršenom stanju.
            </p>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
