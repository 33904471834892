import React, { useState, useEffect } from "react";
import "../../styles/Statistic.scss";
import { ScrollAnimation } from "../ScrollAnimation/ScrollAnimation";

export function Statistic() {
  const [year, setYear] = useState(0);
  const [kilometers, setKilometers] = useState(0);
  const [deliveredPackages, setDeliveredPackages] = useState(0);
  const [furnitureMoved, setFurnitureMoved] = useState(0);

  useEffect(() => {
    const targetYear = 4;
    const targetKilometers = 250000;
    const targetPackages = 15000;
    const targetFurniture = 10000;

    const updateNumbers = () => {
      setYear((prev) => (prev < targetYear ? prev + 1 : targetYear));
      setKilometers((prev) =>
        prev < targetKilometers ? prev + 2500 : targetKilometers
      );
      setDeliveredPackages((prev) =>
        prev < targetPackages ? prev + 200 : targetPackages
      );
      setFurnitureMoved((prev) =>
        prev < targetFurniture ? prev + 100 : targetFurniture
      );
    };

    const interval = setInterval(() => {
      updateNumbers();
      if (
        year >= targetYear &&
        kilometers >= targetKilometers &&
        deliveredPackages >= targetPackages &&
        furnitureMoved >= targetFurniture
      ) {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [year, kilometers, deliveredPackages, furnitureMoved]);

  return (
    <div className="statistic_container">
      <div className="title-main">
        <h1 className="statistic-title">
          Transportno rješenje prilagođeno Vašim potrebama
        </h1>
      </div>
      {/* <div className="subtitle-main">
        <p className="statistic-subtitle">Međunarodni transport robe kombi vozilima diljem Europe</p>
      </div> */}
      <ScrollAnimation>
        {/* <div className="image-content">
          <img src="/assets/europe-map.jpeg" alt="europe" />
        </div> */}
      </ScrollAnimation>
      <ScrollAnimation>
        <div className="individual-statistic">
          <div className="year">
            {year.toLocaleString()} +{" "}
            <div className="statistic-label">
              godina
              <br />
              rada
            </div>
          </div>
          <div className="kilometers">
            {kilometers.toLocaleString()} +{" "}
            <div className="statistic-label">
              prijeđenih
              <br />
              kilometara
            </div>
          </div>
          <div className="packages">
            {deliveredPackages.toLocaleString()} +{" "}
            <div className="statistic-label">
              dostavljenih
              <br />
              paketa
            </div>
          </div>
          <div className="furniture">
            {furnitureMoved.toLocaleString()} +{" "}
            <div className="statistic-label">
              zadovoljnih
              <br />
              korisnika
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
