import { Button } from "../ui/Button";
import "../styles/FurnitureService.scss";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";

export function FurnitureService() {
  const handleCallClickMobile2 = () => {
    window.location.href = "tel:+385915151011";
  };

  return (
    <div className="furniture-container">
      <div className="furniture-content">
        <ScrollAnimation>
          <div className="furniture-text">
            <p className="above-title">
              PROFESIONALNA USLUGA ZA SVAKI TIP NAMJEŠTAJA
            </p>
            <h2 className="furniture-title">MONTAŽA NAMJEŠTAJA</h2>
            <p className="description">
              Nudimo stručnu montažu svih vrsta namještaja, uključujući
              pločasti, tapacirani i ugradbeni namještaj, s ciljem da vaš
              prostor bude funkcionalan i estetski savršen. Naša ekipa iskusnih
              majstora brine se o svim detaljima, od preciznog sklapanja do
              finalne postavke, kako bi vaša kupovina namještaja bila bez stresa
              i problema.
            </p>
            <ul className="description">
              <li>
                <div className="inline-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="icon"
                  >
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                  </svg>
                  <p>MONTAŽA PLOČASTOG NAMJEŠTAJA</p>
                </div>

                <p>
                  Sastavljanje svih vrsta pločastog namještaja, poput ormara,
                  komoda, polica, kuhinjskih elemenata i drugih komada, u skladu
                  sa uputstvima proizvođača. Osiguravamo da sve komponente budu
                  pravilno povezane i stabilne, a prostor maksimalno iskoršćen.
                </p>
              </li>
              <li>
                <div className="inline-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="icon"
                  >
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                  </svg>
                  <span>MONTAŽA TAPACIRANOG NAMJEŠTAJA</span>
                </div>
                <p>
                  Stručno sklapanje i postavljanje tapaciranih komada namještaja
                  poput sofa, fotelja i kreveta. Naši majstori paze na svaki
                  detalj, od ugradnje okvira do pravilnog postavljanja
                  materijala i dodataka.
                </p>
              </li>
              <li>
                <div className="inline-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    className="icon"
                  >
                    <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" />
                  </svg>
                  <span>MONTAŽA UGRADBENOG NAMJEŠTAJA</span>
                </div>
                <p>
                  Pruža vam mogućnost da maksimalno iskoristite svoj prostor sa
                  ugradbenim elementima. Bilo da se radi o ugradbenim ormarima,
                  policama, radnim stolovima ili kuhinjskim elementima, naša
                  usluga montaže omogućava savršeno uklapanje u vaš interijer.
                </p>
              </li>
            </ul>
            <div className="furniture-button">
              <Button
                type="button"
                className="btn-contact"
                name="Kontaktirajte nas"
                onClick={handleCallClickMobile2}
              />
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation>
          <div className="furniture-image">
            <img src="/assets/services/unos.jpg" alt="unos" className="img1" />
            <img
              src="/assets/services/montaza.jpg"
              alt="montaza"
              className="img2"
            />
            <img
              src="/assets/services/demontaza.jpg"
              alt="demontaza"
              className="img3"
            />
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
}
