import { Button } from "../ui/Button";
import "../styles/TransportService.scss";
import { ScrollAnimation } from "../components/ScrollAnimation/ScrollAnimation";

export function TransportService() {
  const handleCallClickMobile = () => {
    window.location.href = "tel:+385915151011";
  };

  return (
    <div className="service-container">
      <div className="title-main">
        <h1 className="transport-title">Naše usluge</h1>
      </div>
      <ScrollAnimation>
        <div className="transport-content">
          <div className="transport-text">
            <p className="above-title">BRZA I SIGURNA DOSTAVA</p>
            <h2 className="service-title">DOSTAVA IZ TRGOVAČKIH CENTARA</h2>
            <p className="description">
              Brza i pouzdana dostava robe direktno na Vašu adresu!
              <br />
              Bilo da ste kupili proizvode u fizičkoj prodavaonici ili na web
              shopu, mi smo tu da Vam olakšamo svakodnevicu.
              <br />
              Naša usluga dostave omogućava Vam da dobijete sve što vam je
              potrebno, bez obzira na to gdje ste obavili kupovinu.
            </p>
            <div className="transport-button">
              <Button
                type="button"
                className="btn-contact"
                name="Kontaktirajte nas"
                onClick={handleCallClickMobile}
              />
            </div>
          </div>
          <div className="delivery-image">
            <img
              src="/assets/services/utovar.jpg"
              alt="utovar"
              className="img1"
            />
            <img
              src="/assets/services/paleta.jpg"
              alt="paleta"
              className="img2"
            />
            <img
              src="/assets/services/paketi.jpg"
              alt="paket"
              className="img3"
            />
          </div>
        </div>
      </ScrollAnimation>
      <ScrollAnimation>
        <div>
          <div className="international-content">
            <div className="transport-image">
              <img
                src="/assets/dostava.png"
                alt="route"
                className="route-image"
              />
            </div>
            <div className="delivery-text">
              <p className="above-title">BILO KOJE ODREDIŠTE</p>
              <h2 className="service-title">DOSTAVA PAKETA</h2>
              <p className="description">
                Naša usluga dostave paketa pruža brzu, sigurnu i pouzdanu
                isporuku sa fleksibilnim opcijama dostave prema vašim potrebama.
                Bilo da šaljete ili primate paket, omogućavamo različite načine
                dostave kako bi proces bio što jednostavniji i efikasniji.
              </p>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </div>
  );
}
