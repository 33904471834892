import { Link } from "react-router-dom";
import "../../styles/Footer.scss";
import { SocialMedia } from "../../components/SocialMedia/SocialMedia";

export function Footer() {
  const legalLinks = [
    { text: "Odredbe i uvjeti", url: "/terms" },
    { text: "Kolačići", url: "/cookies" },
    { text: "Politika privatnosti", url: "/privacy-policy" },
  ];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleCallClickMobile = () => {
    window.location.href = "tel:+385915151011";
  };
  const handleCallClickPhone = () => {
    window.location.href = "tel:+38531280423";
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-content">
          <div className="company-info">
            <h2>O nama</h2>
            <p>
              ROBERT Logistika j.d.o.o sa sjedištem na Livani uspješno posluje
              od 1.rujna 2020.g. na prostoru Republike Hrvatske. RL grupacija se
              bavi kombi prijevozom, dostavom iz trgovačkih centara i montažom
              namještaja.
            </p>
            <img src="/assets/logo_w.png" alt="" />
          </div>
          <div className="bank">
            <h2>Tvrtka</h2>
            <ul>
              <li>Robert Logistika j.d.o.o. za usluge prijevoza</li>
              <li>OIB: 19360102927</li>
              <li>MBS: 05326664</li>
            </ul>
            <div className="border"></div>
            <ul>
              <li>PRIVREDNA BANKA d.d. (PBZ)</li>
              <li>Sjedište: Zagreb, Radnička cesta 44</li>
              <li>IBAN: HR7623400091111101366</li>
            </ul>
            <div className="border"></div>
            <ul>
              <li>HPB d.d.</li>
              <li>Sjedište: Zagreb, Jurišićeva ulica 4</li>
              <li>IBAN: HR1123900011101508781</li>
            </ul>
          </div>
          <div className="services">
            <h2>Naše Usluge</h2>
            <ul>
              <li>Dostava iz trgovačkih centara</li>
              <li>Paletna dostava</li>
              <li>Dostava paketa</li>
              <li>Unos kupljene robe</li>
              <li>Montaža namještaja</li>
              <li>Demontaža i pakiranje</li>
            </ul>
          </div>
          <div className="contact-us">
            <h2>Kontakt</h2>
            <ul className="link">
              <li>
                Email:{" "}
                <a href="mailto:info@robert-logistika.hr">
                  info@robert-logistika.hr
                </a>
              </li>
              <li onClick={handleCallClickPhone}>Ured: +38531280423</li>
              <li onClick={handleCallClickMobile}>Robert: +385915151011</li>
              <li>Adresa: Omladinska ul. 119, 31431 Livana</li>
            </ul>
            <SocialMedia />
          </div>
        </div>
      </div>
      <div className="copyright">
        <p>
          &copy; Robert Logistika {new Date().getFullYear()}. Sva prava
          pridržana.
        </p>
      </div>
      <div className="legal-links">
        {legalLinks.map((link, index) => (
          <Link
            to={link.url}
            key={index}
            className="p-link"
            style={{
              padding: "5px 5px",
              // borderRight:
              //   index !== legalLinks.length - 1
              //     ? "1px solid white"
              //     : "none",
              textDecoration: "none",
              color: "white",
              cursor: "pointer",
            }}
            onClick={scrollToTop}
          >
            {link.text}
          </Link>
        ))}
      </div>
    </footer>
  );
}

export default Footer;
